<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-md-12">
        <span class="text-2xl font-bold">Notification Type</span>
      </div>
      <div class="col-md-8 py-2">
        <div class="form-group">
          <select @change="filterNotification" name="notification type" class="form-control">
            <option @click.prevent="getAllNotifications" value="">All</option>
            <option
              v-for="notify in notification_type"
              :key="notify.id"
              :selected="notify.typeId == 1"
              :value="notify.id"
              >{{ notify.fullName }}</option
            >
          </select>
        </div>
         <div class="flex justify-center">
            <b-spinner
              v-if="loading"
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
            ></b-spinner>
          </div>
        <div v-if="notifications.length > 0">
         
          <div class="col-md-12 space-y-10">
            <div
              v-for="notify in notifications"
              :key="notify.index"
              class=" rounded-xl bg-light   p-4"
            >
              <h3 class="text-oonpayprimary">{{ notify.typeFullName }}</h3>
              <p class="text-lg tracking-wide">
                {{ notify.message }}
              </p>
              <div
                class="flex text-oonpayprimary  font-black text-xl justify-end"
              >
                {{ notify.createdDate | formatDate("LL") }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-info text-center py-10">
            There is no notification for you at this moment please come back
            later
          </p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";

export default {
  components: { Layout },
  data() {
    return {
      notifications: [],
      Notdata:[],
      notification_type: [],
      loading: false,
    };
  },
  methods: {
    getAllNotifications() {
      this.loading = true
      this.$store.dispatch("invoice/getAllNotifications").then((response) => {
        this.notifications = response.data.item1;
        this.Notdata = response.data.item1;
        this.notification_type = response.data.item2;
        this.loading = false
      });
    },

    filterNotification(e){
      this.notifications = this.Notdata.filter(el=>el.typeId == e.target.value)
    }
  },
  mounted() {
    this.getAllNotifications();
  },
};
</script>

<style scoped>
p.tab {
  cursor: pointer;
}

.btn-success {
  background: #0c4369 !important;
  border: none;
}
</style>
